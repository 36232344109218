<script setup>
const { initAuth, isLogged, authUser, logout } = useAuth()
const showSidebar = ref(false)
const showLanguageSidebar = ref(false)
const { Settings } = useSettings()
const search = ref('')
const customURLDialog = ref(null)

useHead({
  link: [
    { rel: 'stylesheet', href: '/custom_css', defer: true, async: true }
  ]
})

const menuItems = [
  { title: 'About', path: '/about', icon: 'mdi-help-circle-outline'},
  { title: 'Blobs', path: '/blobs', icon: 'mdi-book-multiple-outline' },
  { title: 'Tags', path: '/tags', icon: 'mdi-tag-multiple-outline' },
  { title: 'Share', path: '/share', icon: 'mdi-widgets-outline' },
]

const isValidUrl = urlString => /^https?:\/\/.+\..+/.test(urlString)
const isURL = computed( () => isValidUrl(search.value))
const searchIconString = computed(() => isURL.value ? 'mdi-search-web' : 'mdi-magnify')

async function change () {
  if (Settings.value.allowAddURL && isURL.value) {
    customURLDialog.value.open(search.value)
  } else {
    navigateTo({ path: '/search', query: { query: search.value }})
  }
}

async function customURLAdded () {
  search.value = ''
  // await refresh()
}

onBeforeMount(initAuth)

</script>

<template>
  <v-app>
    <client-only>
      <Dialog />
      <Notification />
    </client-only>
    
    <v-navigation-drawer v-model="showSidebar" temporary id="blob_sidebar">
      <v-list nav>
        <v-list-item
        v-for="item in menuItems"
          :class="item.title"
          :key="$t(item.title)"
          :to="item.path">
            <v-icon>{{ item.icon }}</v-icon>
            <span class="label">{{ item.title }}</span>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  
    
  <v-navigation-drawer location="right" v-model="showLanguageSidebar" temporary>
    <v-card-title>{{$t('Language')}}</v-card-title>
    <v-list nav>
      <v-list-item
        v-for="item in $i18n.locales" :active="$i18n.locale === item.code"
        :key="item.code" @click="$i18n.setLocale(item.code)">
          {{ item.name }}
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar id="blob_appbar" app class="text-primary" elevation="0">
    <v-app-bar-nav-icon class="d-flex d-sm-none" @click="showSidebar = !showSidebar" />
    <v-toolbar-title>
        <nuxt-link class='text-decoration-none' to='/'>
          <img id="blob_logo" :src='"/media/logo"' :alt='Settings.name' />
        </nuxt-link>
    </v-toolbar-title>
    <v-text-field v-if='Settings.enableSearch' variant="outlined"
      hide-details :placeholder="$t(Settings.allowAddURL ? 'Search or paste an URL' : 'Search')" single-line
      @change='change' v-model='search' @keypress.enter="change"
      :prepend-inner-icon="searchIconString" class='mr-2 text-black d-none d-sm-inline' />
    <v-toolbar-items>
    <client-only>
      <CustomURLDialog ref="customURLDialog" @added="customURLAdded"/>
    </client-only>      
    <v-btn class="d-none d-sm-flex" :class="item.title"
      v-for="item in menuItems"
      :key="item.path"
      :to="item.path">
      <v-icon>{{ item.icon }}</v-icon>
      <span class='label'>{{ $t(item.title) }}</span>
    </v-btn>
    <v-btn class='Translate' @click="showLanguageSidebar = !showLanguageSidebar" ><v-icon>mdi-translate</v-icon></v-btn>
    <client-only v-if="isLogged" class="d-none d-sm-flex">
      <v-menu open-on-hover rounded="0">
        <template v-slot:activator="{ props }"><v-btn v-bind="props"><v-icon>mdi-menu</v-icon></v-btn></template>
        <v-card width="250" rounded="0">
          <v-card-text>
            <v-btn to='/admin' color='primary' block class="mb-3">{{ $t('Admin') }}</v-btn>
            <v-btn block color="primary" @click="logout">{{ $t('Signout') }}</v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
      <template #placeholder><v-btn icon='mdi-menu' /></template>
    </client-only>
    <v-btn v-else to="/signin" class="d-none d-sm-flex"><v-icon>mdi-lock-open-outline</v-icon></v-btn>
    </v-toolbar-items>
</v-app-bar>

<v-main id="blob_main">
  <a name='content' id='content'></a>
  <v-container>
    <NuxtPage />
  </v-container>
</v-main>
</v-app>
</template>

<style>
.page-enter-active,.page-leave-active {  transition: all 0.4s;}.page-enter-from,.page-leave-to {  opacity: 0;  filter: blur(1rem);}

#blob_logo {
  height: 50px;
}

#blob_main {
  padding-top: 68px;
  background-color: #fafafa;
}

</style>